import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "cocoacasino",
  casinoId: 12,
  GA_TRACKING: "G-FDY3CGT2LK",

  assetsPath: "assets/brands/cocoa",

  rivalChatGroupName: "Cocoa Casino",
  prettyName: "Cocoa Casino",
  contactEmail: "support@cocoacasino.com",
  docsEmail: "documents@cocoacasino.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/cocoa/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/cocoa/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/cocoa/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/cocoa/promo.png"}
  ],

  legalData: {
    withdrawalTime: 12,
    minDepositAmount: 5,
    affTerms: true
  },

  //SEO
  metaDescription: "Play top slots online at Cocoa Casino with live dealer table games. Join Cocoa online casino to earn no deposit bonuses, free spins, welcome bonus and free cash rewards fast. Secure transactions and safe deposits even in crypto. Double your money at cocoa casino, get the payout today!",
  logoName: "cocoa-casino-online-casino-logo.png",
  logoAltText: "Official logo of Cocoa Casino with playful design with the integration of a cherry and chocolate theme. Online casino representative of fun, playing slots and table games, exciting promotions. This includes being an online casino for satisfying online game playing in a sweet and safe way at Cocoa Casino."

};


